<template class="h-100">
  <div class="h-100">
    <div class="card h-100">
      <div class="card-body h-100">
        <apexchart
          :options="chartOptions"
          :series="series"
          type="bar"
          width="100%"
          height="400"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* .apexcharts-legend-text {
  font-size: 15px !important;
  font-weight: bold !important;
} */
</style>

<script>
import ApexCharts from "apexcharts";
import module from "@/core/modules/CrudModule.js";
export default {
  props: {
    statistic: Object,
  },

  components: {
    ApexCharts,
  },

  data() {
    return {
      chartOptions: {},
      series: [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          // height: 350,
          type: "bar",
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          // fontSize: "20px",
        },
        xaxis: {
          categories: [],
          labels: {
            // style: {
            //   fontSize: "15px",
            // },
          },
        },
        yaxis: {
          labels: {
            // style: {
            //   fontSize: "15px",
            // },
          },
        },
        stroke: {
          curve: "smooth",
        },

        colors: ["#38c762", "#f47b20"],
        tooltip: {
          x: {
            // formatter: function (val) {
            //   return val;
            // },
          },
        },
      },
    };
  },

  methods: {
    async getDataMale(tool) {
      window.arrData = [];
      console.log(this.statistic, "this.statistic");
      let response = await module.get("statistic");
      response = response.items.ageMale;

      let male = [];

      let category1 = [],
        category2 = [],
        category3 = [],
        category4 = [],
        category5 = [],
        category6 = [],
        category7 = [];

      let amount1 = 0,
        amount2 = 0,
        amount3 = 0,
        amount4 = 0,
        amount5 = 0,
        amount6 = 0,
        amount7 = 0;

      console.log(response, "response");

      for (let a = 0; a < response.length; a++) {
        if (response[a].age >= 0 && response[a].age <= 5) {
          category1.push(response[a]);
        } else if (response[a].age > 5 && response[a].age <= 12) {
          category2.push(response[a]);
        } else if (response[a].age > 12 && response[a].age <= 17) {
          category3.push(response[a]);
        } else if (response[a].age > 17 && response[a].age <= 25) {
          category4.push(response[a]);
        } else if (response[a].age > 25 && response[a].age <= 35) {
          category5.push(response[a]);
        } else if (response[a].age > 35 && response[a].age <= 45) {
          category6.push(response[a]);
        } else if (response[a].age > 45) {
          category7.push(response[a]);
        }
      }

      //  0-5 tahun
      for (let b = 0; b < category1.length; b++) {
        amount1 = amount1 + category1[b].amount;
      }

      console.log(amount1, "amount1");

      male.push({
        label: "0-5",
        amount: amount1,
      });

      // 6-12 tahun
      for (let c = 0; c < category2.length; c++) {
        amount2 = amount2 + category2[c].amount;
      }

      male.push({
        label: "6-12",
        amount: amount2,
      });

      // 13-17 tahun
      for (let d = 0; d < category3.length; d++) {
        amount3 = amount3 + category3[d].amount;
      }

      male.push({
        label: "13-17",
        amount: amount3,
      });

      // 18-25 tahun
      for (let e = 0; e < category4.length; e++) {
        amount4 = amount4 + category4[e].amount;
      }

      male.push({
        label: "18-25",
        amount: amount4,
      });

      // 26-35 tahun
      for (let f = 0; f < category5.length; f++) {
        amount5 = amount5 + category5[f].amount;
      }

      male.push({
        label: "26-35",
        amount: amount5,
      });

      // 36-45 tahun
      for (let g = 0; g < category6.length; g++) {
        amount6 = amount6 + category6[g].amount;
      }

      male.push({
        label: "36-45",
        amount: amount6,
      });

      // over 45 tahun
      for (let h = 0; h < category7.length; h++) {
        amount7 = amount7 + category7[h].amount;
      }

      male.push({
        label: ">45",
        amount: amount7,
      });

      this.series[0].name = "Laki-laki";
      this.series[0].data = male.map((male) => parseInt(male.amount));
      window.arrData = male.map((male) => String(male.label));
      // this.chartLoad = true;
    },

    async getDataFemale(tool) {
      let response = await module.get("statistic");
      response = response.items.ageFemale;

      let female = [];

      let category1 = [],
        category2 = [],
        category3 = [],
        category4 = [],
        category5 = [],
        category6 = [],
        category7 = [];

      let amount1 = 0,
        amount2 = 0,
        amount3 = 0,
        amount4 = 0,
        amount5 = 0,
        amount6 = 0,
        amount7 = 0;

      console.log(response, "response");

      for (let a = 0; a < response.length; a++) {
        if (response[a].age >= 0 && response[a].age <= 5) {
          category1.push(response[a]);
        } else if (response[a].age > 5 && response[a].age <= 12) {
          category2.push(response[a]);
        } else if (response[a].age > 12 && response[a].age <= 17) {
          category3.push(response[a]);
        } else if (response[a].age > 17 && response[a].age <= 25) {
          category4.push(response[a]);
        } else if (response[a].age > 25 && response[a].age <= 35) {
          category5.push(response[a]);
        } else if (response[a].age > 35 && response[a].age <= 45) {
          category6.push(response[a]);
        } else if (response[a].age > 45) {
          category7.push(response[a]);
        }
      }

      //  0-5 tahun
      for (let b = 0; b < category1.length; b++) {
        amount1 = amount1 + category1[b].amount;
      }

      console.log(amount1, "amount1");

      female.push({
        label: "0-5",
        amount: amount1,
      });

      // 6-12 tahun
      for (let c = 0; c < category2.length; c++) {
        amount2 = amount2 + category2[c].amount;
      }

      female.push({
        label: "6-12",
        amount: amount2,
      });

      // 13-17 tahun
      for (let d = 0; d < category3.length; d++) {
        amount3 = amount3 + category3[d].amount;
      }

      female.push({
        label: "13-17",
        amount: amount3,
      });

      // 18-25 tahun
      for (let e = 0; e < category4.length; e++) {
        amount4 = amount4 + category4[e].amount;
      }

      female.push({
        label: "18-25",
        amount: amount4,
      });

      // 26-35 tahun
      for (let f = 0; f < category5.length; f++) {
        amount5 = amount5 + category5[f].amount;
      }

      female.push({
        label: "26-35",
        amount: amount5,
      });

      // 36-45 tahun
      for (let g = 0; g < category6.length; g++) {
        amount6 = amount6 + category6[g].amount;
      }

      female.push({
        label: "36-45",
        amount: amount6,
      });

      // over 45 tahun
      for (let h = 0; h < category7.length; h++) {
        amount7 = amount7 + category7[h].amount;
      }

      female.push({
        label: ">45",
        amount: amount7,
      });

      this.series[1].name = "Perempuan";
      this.series[1].data = female.map((female) => parseInt(female.amount));
      // window.arrData = female.map((female) => String(female.label));
      this.chartLoad = true;

      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: window.arrData,
          },
        },
      };
    },
  },
  computed: {},
  mounted() {
    this.getDataMale();
    this.getDataFemale();
  },
};
</script>
