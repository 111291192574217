<template>
  <div
    style="
      background: white;
      height: 100%;
    "
  >
    <div class="row px-2">
      <div class="col-md-12">
        <ReligionChart></ReligionChart>
      </div>
    </div>
  </div>
</template>

<script>
import ReligionChart from "@/view/components/statistic-website/AgeChartWebsite.vue";

export default {
  name: "statistic",
  components: {
    ReligionChart,
  },
};
</script>

